
import { CountryLang } from '@/utils/constBase'
import { reachableCountry } from '@/utils/const'
export default {
  props: {
    error: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  asyncData({ route }) {
    const {
      params: { site, lang },
    } = route
    return { site, lang }
  },
  data() {
    return {}
  },
  computed: {
    getHomeUrl() {
      //如果站点不存在
      if (!reachableCountry[this.site]) {
        return '/cn/sc/'
      } else {
        const curSiteLang = CountryLang[this.site]?.lang
        //不支持的语言
        if (!curSiteLang?.some((item) => item.code === this.lang)) {
          return `/${this.site}/sc/`
        } else {
          //站点，语言都支持
          return `/${this.site}/${this.lang}/`
        }
      }
    },
  },
  created() {},
  mounted() {},
  methods: {},
}
